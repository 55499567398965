import React, { useState, useContext, useEffect } from 'react';
import { XMarkIcon, ArrowPathIcon, InformationCircleIcon, EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import { ConnectorsContext } from '../../contexts/ConnectorsContext';

const ConnectorModal = ({ connector, onClose, onSave }) => {
  const { addConnectorToOrg } = useContext(ConnectorsContext);
  const [configurations, setConfigurations] = useState(connector.configurations || []);
  const [isSaving, setIsSaving] = useState(false);
  const [errors, setErrors] = useState({});
  const [activeTab, setActiveTab] = useState(configurations[0]?.id || '');
  const [searchTerm, setSearchTerm] = useState('');
  const [showAllScopes, setShowAllScopes] = useState(false);
  const [useCustomRedirect, setUseCustomRedirect] = useState(false);
  const [generatedLink, setGeneratedLink] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const hostedRedirectUri = `https://auth.fiscusflows.com/redirect`;

  // Function to initially generate the redirect link based on the backend or hosted link
  const handleGenerateLink = (configId) => {
    const config = configurations.find((config) => config.id === configId);
    const field = config?.fields.find((field) =>
      field.name.toLowerCase().includes('redirect') || field.name.toLowerCase().includes('uri')
    );

    if (field) {
      const initialUri = config.credentials[field.name] || hostedRedirectUri;
      handleChange(configId, field.name, initialUri);
      setGeneratedLink(initialUri);
      setUseCustomRedirect(initialUri !== hostedRedirectUri);
    }
  };

  // Auto-setup generated link on initial load
  useEffect(() => {
    // Triggered once to initialize the link on load if missing
    if (!generatedLink) {
      handleGenerateLink(activeTab);
    }
  }, [activeTab]);  // Only depends on activeTab initially
  
  useEffect(() => {
    const config = configurations.find((config) => config.id === activeTab);
	console.log(`Initial Config Object: `, config);
    const redirectField = config?.fields.find((field) =>
      field.name.toLowerCase().includes('redirect') || field.name.toLowerCase().includes('uri')
    );
  
    // Only set the generated link if needed and if we haven't already set it
    if (redirectField) {
      const initialUri = config.credentials[redirectField.name] || hostedRedirectUri;
      if (generatedLink !== initialUri) {
        setGeneratedLink(useCustomRedirect ? initialUri : hostedRedirectUri);
      }
    }
  }, [useCustomRedirect, activeTab, configurations]);  // Reduced dependencies
  
  const handleChange = (configId, fieldName, value) => {
    // Find the field type directly from `fieldMappings`
    const fieldMapping = configurations
      .find((config) => config.id === configId)
      ?.fields.find((field) => field.name === fieldName);
  
    const fieldType = fieldMapping?.type; // Get type from `fieldMappings`
  
    setConfigurations((prevConfigs) =>
      prevConfigs.map((config) =>
        config.id === configId
          ? {
              ...config,
              credentials: {
                ...config.credentials,
                [fieldName]: fieldType === 'array'
                  ? Array.isArray(value) ? value : value.split(',').map((item) => item.trim())
                  : value
              },
            }
          : config
      )
    );
  };
  
  const validate = () => {
    const newErrors = {};
    configurations.forEach((config) => {
        config.fields?.forEach((field) => {
            if (field.required && !config.credentials[field.name]) {
                newErrors[`${config.id}_${field.name}`] = `${field.label} is required.`;
            }
        });
    });
    setErrors(newErrors);
    console.log("Validation errors:", newErrors);  // Log validation errors here
    return Object.keys(newErrors).length === 0;
};

  const filteredScopes = (config) => {
    return config.scopes?.filter((scope) =>
      scope.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  const handleSave = async () => {
    if (!validate()) {
      console.log("Validation failed, not proceeding with save.");
      return;
  }
    if (validate()) {
      setIsSaving(true);
      try {
        const updatedConfigurations = configurations.map((config) => {
          if (!useCustomRedirect) {
            const redirectField = config.fields.find((field) =>
              field.name.toLowerCase().includes('redirect') || field.name.toLowerCase().includes('uri')
            );
            if (redirectField) {
              config.credentials[redirectField.name] = hostedRedirectUri;
            }
          }
		  console.log(JSON.stringify(config));
          return config;
        });

        await addConnectorToOrg(connector.id, connector.category, updatedConfigurations);
        onSave(connector.id, { configurations: updatedConfigurations, status: true });
        onClose();
      } catch (error) {
        console.log("Failed to save configurations:", error);
      } finally {
        setIsSaving(false);
      }
    }
  };


  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white dark:bg-gray-800 rounded-lg w-full max-w-2xl p-6 relative">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300"
          aria-label="Close Modal"
        >
          <XMarkIcon className="w-6 h-6" />
        </button>

        <h3 className="text-2xl font-semibold text-gray-800 dark:text-white mb-6">
          {connector.properName} Configuration
        </h3>

        <div className="border-b border-gray-300 dark:border-gray-700 flex overflow-x-auto space-x-4 mb-4">
          {configurations.map((config) => (
            <button
              key={config.id}
              onClick={() => setActiveTab(config.id)}
              className={clsx(
                'px-4 py-2 text-sm font-medium',
                activeTab === config.id
                  ? 'text-primary border-b-2 border-primary'
                  : 'text-gray-500 hover:text-primary-dark'
              )}
            >
              {config.authType}
            </button>
          ))}
        </div>

        {configurations.map((config) => {
          if (config.id !== activeTab) return null;

          const filteredFields = config.fields?.filter((field) =>
            !field.name.toLowerCase().includes('redirect') &&
            !field.name.toLowerCase().includes('uri')
          );

          return (
            <form key={config.id} className="space-y-4">
              {filteredFields.map((field) => (
				<div key={field.name} className="relative">
					<label
					htmlFor={field.name}
					className="block text-sm font-medium text-gray-700 dark:text-gray-300"
					>
					{field.label}
					{field.required && <span className="text-red-500">*</span>}
					</label>
					
					{field.type === 'password' ? (
					<div className="relative">
						<input
						type={showPassword ? 'text' : 'password'}
						name={field.name}
						autoComplete="new-password"
						value={config.credentials[field.name] || ''}
						onChange={(e) => handleChange(config.id, field.name, e.target.value, field.type)}
						placeholder={`Enter ${field.label}`}
						className={clsx(
							'w-full px-4 py-2 border rounded-md bg-gray-100 dark:bg-gray-700 dark:text-white focus:ring-2 transition',
							errors[`${config.id}_${field.name}`] ? 'border-red-500 focus:ring-red-200' : 'border-gray-300 focus:ring-primary'
						)}
						/>
						<button
						type="button"
						onClick={() => setShowPassword(!showPassword)}
						className="absolute inset-y-0 right-0 flex items-center pr-3 focus:outline-none"
						>
						{showPassword ? <EyeSlashIcon className="w-5 h-5 text-gray-500" /> : <EyeIcon className="w-5 h-5 text-gray-500" />}
						</button>
					</div>
					) : field.type === 'array' ? (
					<select
						multiple
						value={config.credentials[field.name] || []}
						onChange={(e) => {
						const options = Array.from(e.target.selectedOptions, option => option.value);
						handleChange(config.id, field.name, options, field.type);
						}}
						className="w-full px-4 py-2 border rounded-md bg-gray-100 dark:bg-gray-700 dark:text-white focus:ring-2 transition"
					>
					</select>
					) : (
					<input
						type={field.type}
						name={field.name}
						autoComplete="off"
						value={config.credentials[field.name] || ''}
						onChange={(e) => handleChange(config.id, field.name, e.target.value, field.type)}
						placeholder={`Enter ${field.label}`}
						className={clsx(
						'w-full px-4 py-2 border rounded-md bg-gray-100 dark:bg-gray-700 dark:text-white focus:ring-2 transition',
						errors[`${config.id}_${field.name}`] ? 'border-red-500 focus:ring-red-200' : 'border-gray-300 focus:ring-primary'
						)}
					/>
					)}
					{errors[`${config.id}_${field.name}`] && (
					<p className="text-red-500 text-xs mt-1">
						{errors[`${config.id}_${field.name}`]}
					</p>
					)}
				</div>
				))}

              {/* OAuth Redirect URI Section */}
              {config.fields.some(field => 
					field.name.toLowerCase().includes('redirect') || 
					field.name.toLowerCase().includes('uri')
				) && (
					<div className="space-y-2 mt-6 border-t pt-4">
						<div className="flex items-center justify-between">
							<h5 className="text-sm font-medium text-gray-700 dark:text-gray-300 flex items-center">
								OAuth Redirect URI
								<div className="relative group ml-2">
									<InformationCircleIcon
										className="w-5 h-5 text-gray-400 cursor-pointer"
									/>
									<div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-1 hidden group-hover:block bg-black text-white text-xs rounded py-1 px-4 w-64">
										Use our hosted URI for automatic redirect handling, or specify your own if needed.
									</div>
								</div>
							</h5>
							<button
								onClick={(e) => {
									e.preventDefault();
									setUseCustomRedirect((prev) => !prev);
								}}
								className="text-primary text-sm hover:text-primary-dark focus:outline-none"
							>
								{useCustomRedirect ? 'Use Hosted Link' : 'Use Custom Link'}
							</button>
						</div>

						{useCustomRedirect ? (
							<div className="mt-2">
								<input
									type="text"
									name="customRedirectUri"
									autoComplete="off"
									value={config.credentials.redirectUri || ''}
									onChange={(e) => handleChange(config.id, 'redirectUri', e.target.value)}
									placeholder="Enter custom redirect URI"
									className="w-full px-4 py-2 border rounded-md bg-gray-100 dark:bg-gray-700 dark:text-white focus:ring-2 focus:ring-primary"
								/>
							</div>
						) : (
							<div className="flex items-center bg-gray-100 dark:bg-gray-700 p-3 rounded-md mt-2">
								<span className="text-sm font-mono text-gray-800 dark:text-gray-200 break-all">
									{generatedLink}
								</span>
							</div>
						)}
					</div>
				)}


              {/* Scopes Selection */}
              {config.scopes && config.scopes.length > 0 && (
                <div className="space-y-2 mt-4">
                  <h5 className="text-sm font-medium text-gray-700 dark:text-gray-300">Select Scopes</h5>
                  <input
                    type="text"
                    placeholder="Search scopes..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="w-full px-4 py-2 border rounded-md bg-gray-100 dark:bg-gray-700 dark:text-white focus:ring-2 focus:ring-primary mb-2"
                  />
                  <div className="max-h-48 overflow-y-auto border border-gray-300 rounded-md p-2 dark:border-gray-700">
                    {filteredScopes(config).slice(0, showAllScopes ? undefined : 5).map((scope) => (
                      <label key={scope} className="flex items-center space-x-2">
                        <input
                          type="checkbox"
                          value={scope}
                          checked={config.credentials.scopes?.includes(scope) || false}
                          onChange={(e) => {
                            const updatedScopes = e.target.checked
                              ? [...(config.credentials.scopes || []), scope]
                              : (config.credentials.scopes || []).filter(s => s !== scope);
                            handleChange(config.id, 'scopes', updatedScopes);
                          }}
                          className="rounded text-primary focus:ring-primary"
                        />
                        <span className="text-sm text-gray-800 dark:text-gray-300">{scope}</span>
                      </label>
                    ))}
                    {filteredScopes(config).length === 0 && (
                      <p className="text-sm text-gray-500 dark:text-gray-400">No scopes found.</p>
                    )}
                  </div>
                  {filteredScopes(config).length > 5 && (
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setShowAllScopes(!showAllScopes);
                      }}
                      className="text-primary hover:text-primary-dark text-sm focus:outline-none mt-2"
                    >
                      {showAllScopes ? 'Show Less' : 'Show All'}
                    </button>
                  )}
                </div>
              )}
            </form>
          );
        })}

        <div className="flex justify-end space-x-2 mt-6">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-300 dark:bg-gray-700 text-gray-700 dark:text-gray-300 rounded hover:bg-gray-400 dark:hover:bg-gray-600 transition"
          >
            Cancel
          </button>
          <button
            onClick={handleSave}
            className="flex items-center px-4 py-2 bg-primary text-white rounded hover:bg-primary-dark transition"
            disabled={isSaving}
          >
            {isSaving ? (
              <>
                <ArrowPathIcon className="w-5 h-5 mr-2 animate-spin" />
                Saving...
              </>
            ) : (
              'Save Configuration'
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConnectorModal;
